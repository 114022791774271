import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-callback',
    standalone: true,
    templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {
    constructor(
        private router: Router,
    ) { }

    async ngOnInit() {
        this.router.navigate(['/']);
    }
}